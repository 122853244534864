import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { useFormContext, useWatch } from "react-hook-form";
import {
  AutocompleteInput,
  BooleanInput,
  Button as RaButton,
  Create,
  Datagrid,
  DateField,
  downloadCSV,
  Edit,
  email,
  EmailField,
  FunctionField,
  List,
  ListActions,
  PasswordInput,
  RecordContextProvider,
  ReferenceManyField,
  regex,
  required,
  SaveButton,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useCreatePath,
  useDataProvider,
  useGetIdentity,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useRedirect,
  useResourceContext,
  useStore,
} from "react-admin";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CakeIcon from "@mui/icons-material/Cake";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import StarsIcon from "@mui/icons-material/Stars";
import { BulkDeleteButton } from "./BulkDeleteButton";
import { BulkExportButton } from "./BulkExportButton";
import { Confirm } from "./Confirm";
import { DeleteButton } from "./DeleteButton";

import inflection from "inflection";
import jsonExport from "jsonexport/dist";
import { isPossiblePhoneNumber } from "libphonenumber-js/max";
import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";

import { countries } from "../resources/countries";

const BadgesField = (props) => {
  const record = useRecordContext(props);
  const theme = useTheme();
  if (!record) return null;
  return (
    <Stack direction="row">
      {record.verified && (
        <Tooltip title="Verified">
          <CheckCircleIcon color="blue" />
        </Tooltip>
      )}
      {record.is_superuser && (
        <Tooltip title="Superuser">
          <StarsIcon color="yellow" />
        </Tooltip>
      )}
      {record.is_staff && (
        // https://www.iconarchive.com/show/material-icons-by-pictogrammers/crown-circle-icon.html
        <Tooltip title="Admin">
          <SvgIcon sx={{ fill: theme.palette.red.main }}>
            {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="mdi-crown-circle"
              viewBox="0 0 24 24"
            >
              <path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" />
            </svg>
          </SvgIcon>
        </Tooltip>
      )}
    </Stack>
  );
};

const ChildCards = (props) => {
  const theme = useTheme();
  const record = useRecordContext();
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  const { permissions } = usePermissions();
  const { data, isPending } = useListContext();

  const getGenderColor = (gender) => {
    switch (gender) {
      case "M":
        return theme.palette.blue2;
      case "F":
        return theme.palette.pink;
      default:
        return theme.palette.black;
    }
  };

  return isPending || !data ? null : data.length ? (
    <Stack spacing={1}>
      {data.map((child) => (
        <RecordContextProvider value={child} key={child.id}>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              action={
                permissions.canEdit && (
                  <>
                    <IconButton
                      component={Link}
                      to={createPath({
                        type: "edit",
                        resource,
                        id: child.id,
                      })}
                      color="white"
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                    <DeleteButton
                      confirmContent="Are you sure you want to delete this child?"
                      color="white"
                      redirect={false}
                    />
                  </>
                )
              }
              style={{
                backgroundColor: getGenderColor(child.gender).main,
                paddingTop: "3px",
                paddingBottom: "3px",
              }}
              title={`${child.first_name ?? ""} ${child.last_name ?? ""}`}
              titleTypographyProps={{
                variant: "span",
                fontWeight: "bold",
                color: getGenderColor(child.gender).contrastText,
              }}
            />
            <CardContent>
              <Stack
                divider={<Divider flexItem />}
                spacing={2}
                alignItems="center"
              >
                <Stack direction="row" spacing={1}>
                  {child.birthdate && <CakeIcon fontSize="small" />}
                  <DateField
                    source="birthdate"
                    options={{
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }}
                    emptyText="No birth date available"
                  />
                </Stack>
                <TextField
                  source="diagnosis"
                  emptyText="No diagnosis specified"
                />
              </Stack>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Stack>
  ) : (
    <Stack>
      <Typography textAlign="center">No children available.</Typography>
      <RaButton
        component={Link}
        to={{
          pathname: createPath({
            type: "create",
            resource: "children",
          }),
          search: `?parent=${record.id}`,
        }}
        label="Add child"
      >
        <AddIcon />
      </RaButton>
    </Stack>
  );
};

const RemoveIntegrationMenuItem = (props) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const { mutate, isLoading } = useMutation({
    mutationFn: () => props.operation(record.id),
    onSuccess: () => {
      notify(
        `Removed ${props.name} integration for ${record.first_name ?? ""} ${record.last_name ?? ""}`,
      );
      refresh();
    },
    onError: () => {
      notify("Failed to remove integration", { type: "error" });
      refresh();
    },
  });

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    props.enabled && (
      <div>
        <MenuItem
          aria-label="Remove"
          color="black"
          onClick={handleClick}
          disabled={isLoading}
        >
          {`Remove ${props.name} integration`}
        </MenuItem>
        <Confirm
          isOpen={open}
          loading={isLoading}
          title={`Remove ${props.name} integration`}
          content={`Are you sure you want to remove the ${props.name} integration for ${record && `${record.first_name ?? ""} ${record.last_name ?? ""}`}?`}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      </div>
    )
  );
};

const AsideOptions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { permissions } = usePermissions();
  const createPath = useCreatePath();
  const dataProvider = useDataProvider();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="user-aside-button"
        aria-controls={open ? "user-aside-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="black"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="user-aside-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={createPath({
            type: "edit",
            resource,
            id: record.id,
          })}
        >
          Edit
        </MenuItem>
        {(permissions.canCreateAdmin ||
          !(record.is_staff || record.is_superuser)) && (
          <MenuItem component={Link} to={`/users/${record.id}/change-password`}>
            Change password
          </MenuItem>
        )}
        {(permissions.canCreateAdmin ||
          !(record.is_staff || record.is_superuser)) && (
          <RemoveIntegrationMenuItem
            name="Facebook"
            enabled={record.has_facebook_id}
            operation={dataProvider.removeFacebookIntegration}
          />
        )}
        {(permissions.canCreateAdmin ||
          !(record.is_staff || record.is_superuser)) && (
          <RemoveIntegrationMenuItem
            name="Google"
            enabled={record.has_google_id}
            operation={dataProvider.removeGoogleIntegration}
          />
        )}
        {(permissions.canCreateAdmin ||
          !(record.is_staff || record.is_superuser)) && (
          <RemoveIntegrationMenuItem
            name="Apple"
            enabled={record.has_apple_id}
            operation={dataProvider.removeAppleIntegration}
          />
        )}
      </Menu>
    </div>
  );
};

const asideWidth = "400px";

const UserListAsideCard = (props) => {
  const theme = useTheme();
  return (
    <Card sx={{ mt: "8px", mb: "3em", ml: "1em", width: asideWidth }}>
      <CardHeader
        action={props.action}
        sx={{
          backgroundColor: theme.palette.yellow.main,
          py: props.action ? "3px" : "7px",
        }}
        title={props.title}
        titleTypographyProps={{
          variant: "span",
          fontWeight: "bold",
        }}
      />
      <CardContent
        sx={{
          "& .RaLabeled-label": { fontWeight: "bold" },
          "& fieldset": {
            borderColor: theme.palette.yellow.main,
            borderRadius: "12px",
          },
          "& legend": {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
      >
        {props.children || <Stack spacing={1} />}
      </CardContent>
    </Card>
  );
};

const UserListAside = (props) => {
  const { data, isPending, selectedIds } = useListContext();
  const { permissions } = usePermissions();

  if (isPending) return null;
  if (!data) return null;
  if (!data.length) return null;
  const record = data?.find((e) => e.id === props.selection);
  if (!record)
    return (
      <UserListAsideCard
        title={`${selectedIds.length} ${inflection.inflect("user", selectedIds.length)} selected`}
      />
    );
  if (!props.selection) return <UserListAsideCard title="No user selected" />;
  return (
    <RecordContextProvider value={record}>
      <UserListAsideCard
        action={permissions.canEdit && <AsideOptions />}
        title={`${record.first_name ?? ""} ${record.last_name ?? ""}`}
      >
        <ReferenceManyField reference="children" target="parent">
          <ChildCards />
        </ReferenceManyField>
      </UserListAsideCard>
    </RecordContextProvider>
  );
};

const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const { first_name, last_name, email, verified } = user;
    return { first_name, last_name, email, verified };
  });
  jsonExport(
    usersForExport,
    {
      headers: ["first_name", "last_name", "email", "verified"],
      rename: ["First Name", "Last Name", "Email", "Verified"],
    },
    (err, csv) => {
      downloadCSV(csv, "users"); // download as 'users.csv` file
    },
  );
};

export const UserList = (props) => {
  const { permissions } = usePermissions();
  const [selection, setSelection] = useStore(
    props.selectionStore ?? "user.list",
    null,
  );
  const theme = useTheme();

  return (
    <List
      // biome-ignore lint/complexity/noUselessFragments: Hide filter button
      actions={<ListActions filters={<></>} />}
      aside={<UserListAside selection={selection} />}
      exporter={exporter}
      filters={[<SearchInput key="search" source="search" alwaysOn />]}
      sort={{ field: "created_at", order: "DESC" }}
      sx={{ m: 2 }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={
          props.bulkActionButtons || (
            <>
              <BulkExportButton color="white" />
              {permissions.canDelete && (
                <BulkDeleteButton
                  mutationMode="pessimistic"
                  mutationOptions={{ meta: { hardDelete: true } }}
                  color="white"
                  deleteSuccessMessage="User deleted |||| %{smart_count} users deleted"
                />
              )}
            </>
          )
        }
        rowClick={(id, resource, record) => {
          setSelection(id);
          return false;
        }}
        rowSx={(record, index) => ({
          backgroundColor:
            record.id === selection ? theme.palette.lightYellow.main : null,
        })}
      >
        <FunctionField
          label="Name"
          render={(record) =>
            `${record.first_name ?? ""} ${record.last_name ?? ""}`
          }
          sortBy="first_name"
        />
        <EmailField source="email" />
        <BadgesField source="verified" label="Badges" />
        {props.extraFields}
      </Datagrid>
    </List>
  );
};

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = (props) => {
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  return (
    <Button
      component={Link}
      to={createPath({ type: "list", resource })}
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const equalToPassword = (value, allValues) => {
  if (value !== allValues.password) return "Passwords must match";
};

const validatePhone = (value) => {
  if (!value) return;
  return isPossiblePhoneNumber(value)
    ? undefined
    : { message: "Invalid phone number" };
};

const PhoneNumberInput = (props) => {
  const { getValues, setValue } = useFormContext();
  const [lastPhoneNumber, setLastPhoneNumber] = useState(null);
  const country = useWatch({ name: "country" });
  const phoneNumber = useWatch({ name: "phone_number" });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(
    () => {
      if (country && lastPhoneNumber !== null) {
        setValue(
          "phone_number",
          `+${countries.find((c) => c.label === country)?.phone}`,
          {
            shouldValidate: true,
            shouldDirty: true,
          },
        );
      }
      const currentPhoneNumber = getValues("phone_number");
      setLastPhoneNumber(currentPhoneNumber ? currentPhoneNumber : '');
    },
    // eslint-disable-next-line
    [country],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(
    () => setValue(
      "phone_number",
      phoneNumber,
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    ),
    // eslint-disable-next-line
    [phoneNumber],
  );

  return (
    <TextInput
      source="phone_number"
      defaultValue={null}
      fullWidth
      validate={[validatePhone]}
      type="tel"
      format={(v) => (v === null ? "" : formatPhoneNumberIntl(v) || v)}
      parse={(v) => (v === "" ? null : parsePhoneNumber(v)?.number || v)}
      {...props}
    />
  );
};

const UserForm = (props) => {
  const { permissions } = usePermissions();

  return (
    <SimpleForm sx={{ px: 3 }} toolbar={<FormToolbar />}>
      <Typography variant="span">Account*</Typography>
      <TextInput
        source="email"
        fullWidth
        type="email"
        validate={[required(), email()]}
      />
      {props.isCreate && (
        <>
          <PasswordInput
            source="password"
            fullWidth
            validate={[
              required(),
              regex(
                /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
                "Must be at least 8 characters long, include at least one uppercase letter, and one number",
              ),
            ]}
          />
          <PasswordInput
            source="confirm_password"
            fullWidth
            validate={[required(), equalToPassword]}
          />
        </>
      )}
      <Divider flexItem sx={{ my: 1 }} />
      <Typography variant="span">Profile</Typography>
      <TextInput source="first_name" fullWidth />
      <TextInput source="last_name" fullWidth />
      <SelectInput
        source="gender"
        fullWidth
        choices={[
          { id: "M", name: "Male" },
          { id: "F", name: "Female" },
          { id: "O", name: "Other" },
        ]}
        emptyText="Prefer not to say"
        emptyValue="N"
      />
      <AutocompleteInput
        source="country"
        fullWidth
        choices={countries.map((c) => ({ id: c.label, name: c.label }))}
      />
      <PhoneNumberInput />
      {permissions.canCreateAdmin && (
        <>
          <BooleanInput source="is_superuser" />
          <BooleanInput source="is_staff" />
        </>
      )}
      <BooleanInput source="verified" />
      {props.isCreate && (
        <>
          <BooleanInput
            source="create_defaults"
            label="Create default categories and words"
            defaultValue={true}
          />
          <BooleanInput
            source="create_blank_child"
            label="Create blank child"
            defaultValue={true}
          />
        </>
      )}
    </SimpleForm>
  );
};

export const UserCreate = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("User created.");
    redirect("list", "users");
  };

  const onError = (error) => {
    notify("Could not create user.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton />
      <Container maxWidth="sm">
        <Create
          title="New User"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="User"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <UserForm isCreate />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const UserEdit = (props) => {
  const { refetch } = useGetIdentity();
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    if (data.id === localStorage.getItem("id")) {
      localStorage.setItem(
        "name",
        `${data.first_name ?? ""} ${data.last_name ?? ""}`,
      );
      refetch();
    }
    notify("User updated.");
    redirect("list", "users");
  };

  const onError = (error) => {
    notify("Could not update user.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton />
      <Container maxWidth="sm">
        <Edit
          title="Edit User"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="User"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <UserForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
