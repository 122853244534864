import { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import {
  Create,
  Datagrid,
  downloadCSV,
  Edit,
  EditButton,
  email,
  EmailField,
  FunctionField,
  List,
  ListActions,
  RecordContextProvider,
  ReferenceManyCount,
  required,
  SaveButton,
  SearchInput,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useCreatePath,
  useDataProvider,
  useListContext,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useResourceContext,
  useShowContext,
  useStore,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { isPossiblePhoneNumber } from "libphonenumber-js/max";
import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";

import inflection from "inflection";
import jsonExport from "jsonexport/dist";

import { BulkDeleteButton } from "./BulkDeleteButton";
import { BulkExportButton } from "./BulkExportButton";
import { Confirm } from "./Confirm";
import { UserList } from "./users";

const asideWidth = "400px";

const AsideOptions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="organization-aside-button"
        aria-controls={open ? "organization-aside-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="black"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="organization-aside-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={createPath({
            type: "show",
            resource,
            id: record.id,
          })}
        >
          Show users
        </MenuItem>
        <MenuItem
          component={Link}
          to={createPath({
            type: "edit",
            resource,
            id: record.id,
          })}
        >
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
};

const OrganizationListAsideCard = (props) => {
  const theme = useTheme();
  return (
    <Card sx={{ mt: "8px", mb: "3em", ml: "1em", width: asideWidth }}>
      <CardHeader
        action={props.action}
        sx={{
          backgroundColor: theme.palette.yellow.main,
          py: props.action ? "3px" : "7px",
        }}
        title={props.title}
        titleTypographyProps={{
          variant: "span",
          fontWeight: "bold",
        }}
      />
      <CardContent
        sx={{
          "& .RaLabeled-label": { fontWeight: "bold" },
          "& fieldset": {
            borderColor: theme.palette.yellow.main,
            borderRadius: "12px",
          },
          "& legend": {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
      >
        {props.children || <Stack spacing={1} />}
      </CardContent>
    </Card>
  );
};

const OrganizationListAside = (props) => {
  const { data, isPending, selectedIds } = useListContext();
  if (isPending) return null;
  if (!data) return null;
  if (!data.length) return null;
  const record = data?.find((e) => e.id === props.selection);
  if (!record)
    return (
      <OrganizationListAsideCard
        title={`${selectedIds.length} ${inflection.inflect("organization", selectedIds.length)} selected`}
      />
    );
  if (!props.selection)
    return <OrganizationListAsideCard title="No organization selected" />;
  return (
    <RecordContextProvider value={record}>
      <OrganizationListAsideCard action={<AsideOptions />} title={record.name}>
        <Stack spacing={1}>
          {record.email && (
            <Box component="fieldset">
              <legend>Email</legend>
              <EmailField source="email" />
            </Box>
          )}
          {record.contact_number && (
            <Box component="fieldset">
              <legend>Contact Number</legend>
              <TextField source="contact_number" />
            </Box>
          )}
        </Stack>
      </OrganizationListAsideCard>
    </RecordContextProvider>
  );
};

const exporter = (organizations, fetchRelatedRecords) => {
  const organizationsForExport = organizations.map((organization) => {
    const { name, email, contact_number } = organization;
    return { name, email, contact_number };
  });
  jsonExport(
    organizationsForExport,
    {
      headers: ["name", "email", "contact_number"],
      rename: ["Name", "Email", "Contact number"],
    },
    (err, csv) => {
      downloadCSV(csv, "organizations"); // download as 'organizations.csv` file
    },
  );
};

export const OrganizationList = (props) => {
  const [selection, setSelection] = useStore(
    props.selectionStore ?? "organization.list",
    null,
  );
  const theme = useTheme();

  return (
    <List
      {...props}
      // biome-ignore lint/complexity/noUselessFragments: Hide filter button
      actions={<ListActions filters={<></>} />}
      aside={<OrganizationListAside selection={selection} />}
      exporter={exporter}
      filters={[<SearchInput key="search" source="search" alwaysOn />]}
      sort={{ field: "name", order: "ASC" }}
      sx={{ m: 2 }}
    >
      <Datagrid
        bulkActionButtons={
          <>
            <BulkExportButton color="white" />
            <BulkDeleteButton
              mutationMode="pessimistic"
              color="white"
              deleteSuccessMessage="Organization deleted |||| %{smart_count} organizations deleted"
            />
          </>
        }
        rowClick={(id, resource, record) => {
          setSelection(id);
          return false;
        }}
        rowSx={(record, index) => ({
          backgroundColor:
            record.id === selection ? theme.palette.lightYellow.main : null,
        })}
      >
        <TextField source="name" />
        <EmailField source="email" />
        <TextField source="contact_number" />
        <ReferenceManyCount
          label="Member count"
          reference="users"
          target="organizations"
        />
      </Datagrid>
    </List>
  );
};

const OrganizationShowData = (props) => {
  const theme = useTheme();
  const { record } = useShowContext();
  if (!record) return null;
  return (
    <Stack direction="row">
      <Card sx={{ flexGrow: 1, m: 2 }}>
        <CardContent
          sx={{
            "& .RaLabeled-label": { fontWeight: "bold" },
            "& fieldset": {
              borderColor: theme.palette.yellow.main,
              borderRadius: "12px",
              m: 1,
            },
            "& legend": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {record.name && (
              <Box component="fieldset">
                <legend>Name</legend>
                <EmailField source="name" />
              </Box>
            )}
            {record.email && (
              <Box component="fieldset">
                <legend>Email</legend>
                <EmailField source="email" />
              </Box>
            )}
            {record.contact_number && (
              <Box component="fieldset">
                <legend>Contact Number</legend>
                <TextField source="contact_number" />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ ml: 2, width: asideWidth }} />
    </Stack>
  );
};

const OrganizationShowAddUserField = (props) => {
  const { record } = useShowContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const userRecord = useRecordContext();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      dataProvider.addUserToOrganization(userRecord.id, {
        data: { id: record.id },
      }),
    onSuccess: () => {
      refresh();
      notify(
        `Added ${userRecord.first_name ?? ""} ${userRecord.last_name ?? ""}`,
      );
    },
    onError: () => {
      refresh();
      notify("Failed to add user");
    },
  });
  const handleAdd = (e) => {
    e.stopPropagation();
    mutate();
  };

  return (
    <FunctionField
      render={(user) => (
        <IconButton
          aria-label="Add"
          color="black"
          onClick={handleAdd}
          disabled={isLoading}
        >
          <AddIcon />
        </IconButton>
      )}
    />
  );
};

const OrganizationShowRemoveUserField = (props) => {
  const { record } = useShowContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const userRecord = useRecordContext();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      dataProvider.removeUserFromOrganization(userRecord.id, {
        data: { id: record.id },
      }),
    onSuccess: () => {
      notify(
        `Removed ${userRecord.first_name ?? ""} ${userRecord.last_name ?? ""}`,
      );
      refresh();
    },
    onError: () => {
      notify("Failed to remove user");
      refresh();
    },
  });

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    <FunctionField
      render={(user) => (
        <>
          <IconButton
            aria-label="Remove"
            color="black"
            onClick={handleClick}
            disabled={isLoading}
          >
            <RemoveIcon />
          </IconButton>
          <Confirm
            isOpen={open}
            loading={isLoading}
            title={"Remove user from organization"}
            content={`Are you sure you want to remove ${userRecord && `${userRecord.first_name ?? ""} ${userRecord.last_name ?? ""}`} from ${record?.name}?`}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </>
      )}
    />
  );
};

const OrganizationShowMembers = (props) => {
  const { record } = useShowContext();
  if (!record) return null;
  return (
    <UserList
      // biome-ignore lint/complexity/noUselessFragments: Hide filter button
      actions={<ListActions hasCreate={false} filters={<></>} />}
      disableSyncWithLocation
      resource="users"
      title=" "
      filter={{ organizations: record.id }}
      extraFields={<OrganizationShowRemoveUserField />}
      disableActions
      bulkActionButtons={<BulkExportButton color="white" />}
      selectionStore="organization.showMembers"
      empty={
        <>
          <Box textAlign="center" mx="auto">
            <Typography variant="h4" paragraph>
              No members in this organization
            </Typography>
            <Typography variant="body1">
              Add members using the list below.
            </Typography>
          </Box>
          <Box sx={{ ml: 2, width: asideWidth }} />
        </>
      }
    />
  );
};

const OrganizationShowAddUsers = (props) => {
  const { record } = useShowContext();
  if (!record) return null;
  return (
    <UserList
      // biome-ignore lint/complexity/noUselessFragments: Hide filter button
      actions={<ListActions hasCreate={false} filters={<></>} />}
      disableSyncWithLocation
      resource="users"
      title=" "
      filter={{ organizations__ne: record.id }}
      extraFields={<OrganizationShowAddUserField />}
      disableActions
      bulkActionButtons={<BulkExportButton color="white" />}
      selectionStore="organization.addUsers"
    />
  );
};

export const OrganizationShow = (props) => {
  return (
    <Show
      actions={
        <TopToolbar>
          <EditButton />
          <Box sx={{ ml: 2, width: asideWidth }} />
        </TopToolbar>
      }
      component={Box}
      title="Organizations"
      {...props}
    >
      <BackButton />
      <Box sx={{ py: 2 }}/>
      <Typography variant="h5" sx={{ mx: 2 }}>
        Info
      </Typography>
      <OrganizationShowData />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Members
      </Typography>
      <OrganizationShowMembers />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Add new members
      </Typography>
      <OrganizationShowAddUsers />
    </Show>
  );
};

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = (props) => {
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  return (
    <Button
      component={Link}
      to={createPath({ type: "list", resource })}
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const validatePhone = (value) => {
  if (!value) return;
  return isPossiblePhoneNumber(value)
    ? undefined
    : { message: "Invalid phone number" };
};

const OrganizationForm = (props) => (
  <SimpleForm sx={{ px: 3 }} toolbar={<FormToolbar />}>
    <TextInput source="name" fullWidth required />
    <TextInput
      source="email"
      fullWidth
      type="email"
      validate={[required(), email()]}
    />
    <TextInput
      source="contact_number"
      fullWidth
      validate={[required(), validatePhone]}
      type="tel"
      format={(v) => (v === null ? "" : formatPhoneNumberIntl(v) || v)}
      parse={(v) => (v === "" ? null : parsePhoneNumber(v)?.number || v)}
    />
  </SimpleForm>
);

export const OrganizationCreate = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("Organization created.");
    redirect("list", "organizations");
  };

  const onError = (error) => {
    notify("Could not create organization.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton />
      <Container maxWidth="sm">
        <Create
          title="New Organization"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Organization"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <OrganizationForm />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const OrganizationEdit = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("Organization updated.");
    redirect("list", "organizations");
  };

  const onError = (error) => {
    notify("Could not update organization.", { type: "error" });
  };

  return (
    <Container maxWidth={false} sx={{ my: { xs: 3 }, px: { xs: 0 } }}>
      <BackButton />
      <Container maxWidth="sm" sx={{}}>
        <Edit
          title="Edit Organization"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Organization"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <OrganizationForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
